import VModal from 'vue-js-modal';
import VueGtm from '@gtm-support/vue2-gtm';
import VueGtag from 'vue-gtag';
import Vue, { VNode } from 'vue';
import VueMoment from 'vue-moment';
import VueMeta from 'vue-meta';
import { registerLocale } from 'i18n-iso-countries';
import BootstrapVue from 'bootstrap-vue';
import GmapVue from 'gmap-vue';
import VueLazyload from 'vue-lazyload';
import { VLazyImagePlugin } from 'v-lazy-image';
import VTooltip from 'v-tooltip';
import IdleVue from 'idle-vue';
import firebase from 'firebase/compat/app';
import { Promised } from 'vue-promised';
import * as Sentry from '@sentry/vue';
import { roles } from '@/store/models/user';
import VueVimeoPlayer from 'vue-vimeo-player';
import VPopover from 'vue-js-popover';
import { i18n } from './i18n';
import { initializeNonUserCollections, initializeUserCollections } from './collections';
// Custom hooks must to be registered before any other components are imported
// @see https://class-component.vuejs.org/guide/additional-hooks.html
import './class-component-hooks';
import App from './App.vue';
import './scss/global/global.scss';
import router from './router';
import store from './store';
import './filters';
import 'hooper/dist/hooper.css';
import { GreenTea } from '../types/greenTea';

const env = process.env;
const NODE_ENV = env.NODE_ENV;
const isDevelopmentEnvironment = NODE_ENV === 'development';
const isProductionEnvironment = NODE_ENV === 'production';
const clientName =
  NODE_ENV === 'production'
    ? process.env.VUE_APP_BRAND
    : process.env.VUE_APP_BLOQIFY_FIREBASE_PROJECT_ID?.split('-bloqify')[0];
const whitelabelConfig: GreenTea = require(`../client-configs/${clientName?.toLowerCase()}.config`);
const enabled2FA = whitelabelConfig.functionality.login.enabled2FA;
/**
 * Register countries list in English locale
 *
 * @see https://github.com/michaelwittig/node-i18n-iso-countries
 */
registerLocale(require('i18n-iso-countries/langs/en.json'));
registerLocale(require('i18n-iso-countries/langs/nl.json'));
registerLocale(require('i18n-iso-countries/langs/de.json'));

Vue.config.devtools = !isProductionEnvironment;
Vue.config.performance = !isProductionEnvironment;
Vue.config.productionTip = false;

Vue.use(VModal, { dialog: true });
Vue.use(VueMoment);
Vue.use(VueMeta);
Vue.use(BootstrapVue);
Vue.use(VueLazyload);
Vue.use(VLazyImagePlugin);
Vue.use(GmapVue, {
  load: {
    key: env.VUE_APP_BLOQIFY_FIREBASE_API_KEY,
    libraries: 'places',
  },
});
Vue.use(IdleVue, {
  store,
  // 3 hours at dev, 30 mins at prod
  idleTime: isDevelopmentEnvironment ? 10_800_000 : 1_800_000,
});
Vue.component('Promised', Promised);
// Tooltip plugin
// @see https://github.com/Akryum/v-tooltip
Vue.use(VTooltip);

Vue.use(VPopover);

Vue.use(VueVimeoPlayer);

if (env.VUE_APP_GOOGLE_GTM) {
  const analyticsConfig = {
    id: env.VUE_APP_GOOGLE_GTM,
    enabled: isProductionEnvironment, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: !isProductionEnvironment, // Whether or not display console logs debugs (optional)
    loadScript: true,
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    // ignoredViews: ['homepage'] // If router, you can exclude some routes name (case insensitive) (optional)
  };

  Vue.use(VueGtm, analyticsConfig);
} else if (env.VUE_APP_GOOGLE_GTM_NEW_VERSION) {
  Vue.use(
    VueGtag,
    {
      config: {
        id: env.VUE_APP_GOOGLE_GTM_NEW_VERSION,
      },
      enabled: isProductionEnvironment,
      disableScriptLoad: !isProductionEnvironment,
    },
    router,
  );
}

if (whitelabelConfig.intercom) {
  Vue.use(require('vue-intercom'), {
    appId: env.VUE_APP_INTERCOM,
  });
}

declare module 'vue/types/vue' {
  interface Vue {
    $intercom: {
      update: (data: { email: string; user_id: string }) => void;
    };
    $greenTea: GreenTea;
  }
}
/**
 * Sentry, error tracking
 */
if (isProductionEnvironment) {
  Sentry.init({
    Vue,
    dsn: env.VUE_APP_BLOQIFY_SENTRY,
    // Documentation: https://docs.sentry.io/platforms/javascript/guides/vue/configuration/integrations
    integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
    environment: env.VUE_APP_BLOQIFY_FIREBASE_PROJECT_ID,
    tracesSampleRate: 1.0,
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    attachProps: true,
  });
}

/**

 * Initialize Vue after Firebase is initialized. We use
 * Vuexfire to bind Firestore collections/documents.
 *
 * @see https://github.com/posva/vuexfire/tree/firestore
 */
export default (authInstance: firebase.auth.Auth, firestoreInstance: firebase.firestore.Firestore): Vue => {
  initializeNonUserCollections(firestoreInstance);

  let app: Vue;
  let previousUser: firebase.User | null;

  authInstance.onAuthStateChanged(async (user): Promise<void> => {
    if (!app) {
      const clientName =
        NODE_ENV === 'production'
          ? process.env.VUE_APP_BRAND
          : process.env.VUE_APP_BLOQIFY_FIREBASE_PROJECT_ID?.split('-bloqify')[0];
      const whitelabelConfig: GreenTea = require(`../client-configs/${clientName?.toLowerCase()}.config`);

      Vue.prototype.$greenTea = {};

      // Adding global variables here

      for (const [key, value] of Object.entries(whitelabelConfig)) {
        Vue.prototype.$greenTea[`${key}`] = value;
      }

      app = new Vue({
        el: '#app',
        i18n,
        router,
        store,
        render: (h): VNode => h(App),
      });

      // When executing E2E Testing using Cypress - lets make the application globally avaible so we can access the store
      window.app = app;
    }

    // Check if the user has any custom role
    const userHasRole = async (tempUser: firebase.User): Promise<boolean> => {
      const tokenResult = await tempUser.getIdTokenResult();
      return roles.some((role): boolean => tokenResult.claims[role]);
    };

    const checkMultifactor = (currentUser: firebase.User): boolean =>
      currentUser.multiFactor.enrolledFactors.some(
        (multiFactor): boolean => multiFactor.factorId === firebase.auth.PhoneMultiFactorGenerator.FACTOR_ID,
      );

    if (user && user.emailVerified && !(await userHasRole(user)) && (!enabled2FA || checkMultifactor(user))) {
      const { uid } = user;
      let removedAssets;

      if (clientName?.toLowerCase() === 'broc') {
        removedAssets = [
          firestoreInstance.collection('assets').doc('46DHv6zxeUoqWvoUgtJV'),
          firestoreInstance.collection('assets').doc('d0PNrBQ4vWscnysgXvsH'),
          firestoreInstance.collection('assets').doc('Rs57vRAKv4a3iSYCGVBp'),
        ];
      }

      void store.dispatch('logInInit', user);
      void initializeUserCollections(firestoreInstance, uid, removedAssets);

      if (app.$intercom) {
        // Sending some information to the intercom admins.
        app.$intercom.update({
          // @ts-expect-error - Wrong types
          email: user.email,
          user_id: uid,
        });
      }
    }

    if (previousUser && !user) {
      void store.dispatch('logOutInit', user);
    }

    previousUser = user;
  });

  // @ts-expect-error - Wrong types
  return app;
};
